.subscription{
.container {
     max-width: 1120px;
     width: 100%;
     margin: 0 auto;

     padding-left: 20px;
     padding-right: 20px;

     @media (min-width: 1200px) {
         padding: unset;
     }

    @media (max-width: 600px) {
        padding-left: 0px;
        padding-right: 0px;
    }

 }
.pricing {
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: 992px) {
        padding-left: 30px;
        padding-right: 60px;
    }

    @media (min-width: 1300px) {
        padding: unset;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 600px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    h2 {
        text-align: center;
        margin-bottom: 28px;

        @media (min-width: 992px) {
            margin-bottom: 32px;
            text-align: left;
        }
    }

    // .pricing__plans

    &__plans {
        display: flex;
        margin-bottom: 160px;
        flex-direction: column;
        row-gap: 28px;

        @media (min-width: 992px) {
            flex-direction: row;
            column-gap: 26px;
            row-gap: unset;
        }


        .plan-3 {
            flex: 0 0 33.333333%;
        }
        .plan-2 {
            flex: 0 0 50%;
        }
    }
}
.plan {
    padding: 32px;
    border-radius: 32px;
    background: #F9F9F9;
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 600px) {
        padding: 32px 20px;
        min-height: 620px;
    }
    &.selected {
        background: var(--Primary-Colour);
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            top: -15px;
            right: -15px;
            background-image: url('../../assets/images/landing/check-mark.png');
            background-size: contain;
            width: 64px;
            height: 64px;

            @media (min-width: 992px) {
                top: -35px;
                right: -35px;
                width: 88px;
                height: 88px;
            }
        }

        h5 {
            color: var(--White, #FFF);
        }

        p {
            color: var(--White, #FFF);
        }

        .btn {
            background: var(--White, #FFF);
            color: var(--Black, #2D2D2D);
        }

        .plan__price {
            h2 {
                color: var(--White, #FFF);

            }
        }

        li {
            color: var(--White, #FFF);

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M6.23612 4.38299C5.28243 4.48958 4.51905 5.24228 4.40871 6.18571C4.1123 8.72 4.1123 11.2802 4.40871 13.8145C4.51905 14.7579 5.28243 15.5106 6.23612 15.6172C8.71681 15.8945 11.2832 15.8945 13.7639 15.6172C14.7175 15.5106 15.4809 14.7579 15.5913 13.8145C15.8042 11.9935 15.8642 10.1592 15.7711 8.33071C15.7687 8.28349 15.7863 8.23743 15.8198 8.204L16.6854 7.33835C16.7856 7.23816 16.9568 7.30081 16.9675 7.44211C17.1308 9.61287 17.0859 11.7954 16.8328 13.9597C16.6538 15.4905 15.4247 16.6894 13.9027 16.8595C11.3297 17.147 8.67024 17.147 6.09728 16.8595C4.57529 16.6894 3.34621 15.4905 3.16717 13.9597C2.85948 11.3289 2.85948 8.67127 3.16717 6.0405C3.34621 4.5097 4.57529 3.31083 6.09728 3.14072C8.67024 2.85316 11.3297 2.85316 13.9027 3.14072C14.4387 3.20063 14.9385 3.38816 15.3688 3.67285C15.4534 3.72888 15.4618 3.84791 15.39 3.9197L14.721 4.58877C14.6662 4.6435 14.5813 4.6527 14.5131 4.61615C14.2853 4.49407 14.0321 4.41297 13.7639 4.38299C11.2832 4.10574 8.71681 4.10574 6.23612 4.38299Z' fill='white'/%3E%3Cpath d='M17.5253 5.02537C17.7693 4.7813 17.7693 4.38557 17.5253 4.14149C17.2812 3.89741 16.8854 3.89741 16.6414 4.14149L9.58331 11.1995L7.52525 9.14149C7.28117 8.89741 6.88544 8.89741 6.64137 9.14149C6.39729 9.38557 6.39729 9.78129 6.64137 10.0254L9.14137 12.5254C9.38544 12.7695 9.78117 12.7695 10.0253 12.5254L17.5253 5.02537Z' fill='white'/%3E%3C/svg%3E");
            }
        }
    }
}

h5 {
    color: #414141;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
    margin: 0;
    margin-bottom: 8px;
}
p {
    color: #848484;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    @media (max-width: 600px) {
        margin-top: 8px;
        margin-bottom: 12px;
    }
}
.plan__price {
    padding: 12px 0;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 24px;
    color: black;
}
.plan__price h2 {
    margin: 0;
    text-align: center;
    font-size: 36px;
    @media (max-width: 600px) {
        font-size: 28px;
    }
}
.plan__benefits {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    @media (max-width: 600px) {
        row-gap: 10px;
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.plan__benefits li {
    position: relative;
    color: #848484;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    padding-left: 24px;
    margin: 0;
}
.plan__benefits li::before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 3px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M6.23615 4.38299C5.28246 4.48958 4.51908 5.24228 4.40874 6.18571C4.11233 8.72 4.11233 11.2802 4.40874 13.8145C4.51908 14.7579 5.28246 15.5106 6.23615 15.6172C8.71684 15.8945 11.2832 15.8945 13.7639 15.6172C14.7176 15.5106 15.481 14.7579 15.5913 13.8145C15.8043 11.9935 15.8642 10.1592 15.7711 8.33071C15.7687 8.28349 15.7864 8.23743 15.8198 8.204L16.6855 7.33835C16.7856 7.23816 16.9568 7.30081 16.9675 7.44211C17.1309 9.61287 17.086 11.7954 16.8328 13.9597C16.6538 15.4905 15.4247 16.6894 13.9027 16.8595C11.3298 17.147 8.67027 17.147 6.09731 16.8595C4.57532 16.6894 3.34624 15.4905 3.1672 13.9597C2.85951 11.3289 2.85951 8.67127 3.1672 6.0405C3.34624 4.5097 4.57532 3.31083 6.09731 3.14072C8.67027 2.85316 11.3298 2.85316 13.9027 3.14072C14.4388 3.20063 14.9385 3.38816 15.3688 3.67285C15.4535 3.72888 15.4619 3.84791 15.3901 3.9197L14.721 4.58877C14.6663 4.6435 14.5814 4.6527 14.5132 4.61615C14.2853 4.49407 14.0321 4.41297 13.7639 4.38299C11.2832 4.10574 8.71684 4.10574 6.23615 4.38299Z' fill='%232D2D2D'/%3E%3Cpath d='M17.5253 5.02537C17.7694 4.7813 17.7694 4.38557 17.5253 4.14149C17.2812 3.89741 16.8855 3.89741 16.6414 4.14149L9.58334 11.1995L7.52528 9.14149C7.2812 8.89741 6.88547 8.89741 6.6414 9.14149C6.39732 9.38557 6.39732 9.78129 6.6414 10.0254L9.1414 12.5254C9.38547 12.7695 9.7812 12.7695 10.0253 12.5254L17.5253 5.02537Z' fill='%232D2D2D'/%3E%3C/svg%3E");
}
.plan__btns .btn {
    width: 100%;
}
.btn {
    display: flex;
    width: 100%;
    padding: 19px 24px 19px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
    color: var(--White);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 130%;
    text-decoration: none;
}
.btn-less {
    padding: 10px 24px;
    font-size: 16px;
}
}